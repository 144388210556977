.sc {
  display: grid;
  margin: 0 auto;

  @include media-minwidth-lg {
    grid-gap: 0 3rem;
    grid-template-columns: 1fr 450px 1fr;
    grid-template-areas: "breadcrumb breadcrumb breadcrumb" "primary primary primary" "sidebar";

    .bc {
      grid-area: "breadcrumb";
      grid-column-start: 1;
      grid-column-end: 4;
      margin: auto;
      max-width: $thin-content;
    }

    #primary {
      grid-area: "primary";
      grid-column-start: 1;
      grid-column-end: 3;

      @include media-minwidth-lg {
        padding-right: 2em;
      }

    }

    .ss {
      /* background: $color-grey-light; */
      border: 1px solid $color-grey-light;
      border-radius: 10px;
      margin: 0;
      padding: 1em;
      h3 {
        font-size: 1.4rem;
        margin: 0 0 0.75em 0;
      }
    }

  }

  @include target-ieonly {
    display: block;
  }

}

.sc--full-width {
  grid-gap: 0;
  grid-template-columns: 100%;
  #primary {
    padding-right: 0;
  }
  @include target-ieonly {
    width: 100%;
  }
}

.thin-container {
  max-width: $thin-content;
  margin: auto;
  padding: 0 1em;
}

.entry-content {
  margin:auto;
  max-width:$thin-content;
  padding:3em 1em;
}

.search-results {
  #content {
    padding:3em 1em;
    margin:auto;
    max-width:$thin-content;
  }
}

