.quote-block  {

  &.quote-small-image-container {
    margin:4% 0;
    padding:0;

    &.padding_remove_bottom {
      margin-bottom:0;
    }

    &.padding_remove_top {
      margin-top:0;
    }

    &.padding_remove_top_and_bottom {
      margin:0;
    }

    .quote-small-image {
      display: grid;
      grid-column-gap: 50px;
      margin:0;
      max-width:100%;
      padding:0;

      @include media-minwidth-md() {
        grid-column-gap: 10px;
        grid-template-columns: 35% 60%;
      }

      @include media-minwidth-lg() {
        grid-column-gap: 50px;
        grid-template-columns: 35% 58%;
        padding-bottom:3em;
      }

      @include media-minwidth-xl() {
        grid-template-columns: 45% 40%;
      }

      @include target-ieonly() {
        display: flex;
        justify-content: space-between;
      }

      img {
        @include curve-bottom-right();
      }

      .quote-content {
        background:none;
        margin:0;
        @include hero-caption();
        padding:2em 3em;

        @include media-minwidth-md() {
          padding:3em;
        }

        @include media-minwidth-lg() {
          padding:3em 0 1em 0;
        }

        @include target-ieonly() {
          margin-left:3em;
        }

      }
    }
  }

  .quote-no-image {
    display: grid;
    grid-gap:25px;
    grid-template-columns: 1fr;

    @include media-minwidth-md() {
      grid-template-columns: 12% 1fr;
      grid-gap:50px;
      padding:0 4em;
    }

    @include target-ieonly() {
      display: flex;
    }

    &.quote-left-column-content {
      @include media-minwidth-md() {
        grid-template-columns: 35% 1fr;
      }
    }

    .quote-content {
      @include hero-caption();
      width:100%;

      @include target-ieonly() {
       width:70%;
      }

    }

    .quote-left-column {

      .quote-left-column-text {
        align-items: center;
        display: flex;
        height:100%;
        justify-content: center;
        text-align: center;
       }

      @include target-ieonly() {
        width:30%;
      }

      .quote-left-column-text {
        font-family: $arial-rounded;
        font-size:3em;
        line-height:0.9em;
        width:100%;

        @include media-minwidth-md() {
          font-size:5em;
        }

        @include media-minwidth-xl() {
          font-size:7em;
        }

      }

      .quote-left-column-symbol {
        display: none;
        font-size:2em;
        @include media-minwidth-md() {
          display: flex;
        }
      }
    }
  }

  .quote-image {
    bottom:0;
    max-width:100%;
    padding:0;
    position: absolute;

    @include media-minwidth-md() {
      position: relative;
    }

    .quote-content {
      width:100%;

      @include media-minwidth-md() {
        padding:2em;
        width:60%;
      }

      @include media-minwidth-lg() {
        padding:3em 4em 3em 2.5em;
        max-width:500px;
      }

      @include media-minwidth-xl() {
        max-width:730px;
      }

    }

  }

}