.archive,
.blog {
  #content {
    padding:2em 1em;
  }
}

.category {
  .post-archive-header {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    h1 {
      width:100%;
    }
    #post-categories-filter {
      margin:1em 0 0 0;
    }
  }
}

.post-archive-header {

  h1 {
    margin-right:1em;
  }

  @include media-minwidth-lg() {
    align-items: center;
    display: flex;
  }

  #post-categories-filter {
    margin:2em 0 0 0;

    @include media-minwidth-lg() {
      margin:0;
    }

  }

}

#post-grid-container {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
  padding:3em 0;

  @include media-minwidth-sm() {
    grid-template-columns: 1fr 1fr;
  }

  @include media-minwidth-lg() {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include target-ieonly() {
    display: flex;
    flex-wrap: wrap;
  }

  article {
    position: relative;
    @include curve-bottom-right();

    @include target-ieonly() {
       margin:1em;
       width:100%;

      @include media-minwidth-sm() {
        width:40%;
      }

      @include media-minwidth-md() {
        width:29%;
      }

     }


    .post-grid-container--image {
      background-color:#e0e0e0;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid #666;
      padding-bottom:55%;
      width:70%;
      @include curve-bottom-right();

      @include media-minwidth-sm() {
        padding-bottom:80%;
        width:100%;
      }

    }

    .post-grid-container--content {
      padding:1em 0;

      @include media-minwidth-md() {
        padding:1em 2em;
      }

      h3 {
        margin:0 0 1em 0;
      }

    }

    .post-grid-container--content-cats {
      margin:1em 0 0 0;

      @include media-minwidth-md() {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin:0.5em 0 0 0;
      }

      span {
        background:$color-yellow;
        display: inline-block;
        margin:0.5em 0.5em 0 0;
        padding:0.25em 1.5em 0.25em 0.5em;
        @include curve-bottom-right();

      }
    }


    a {
      bottom:0;
      height:100%;
      left:0;
      position: absolute;
      width:100%;
    }
  }
}

.post-navigation {
  display: flex;
  margin-top:2em;
  .current,
  .dots{
    padding:0.75em 1em;
    border:1px solid #666;
    margin-right:5px;
  }
  .current {
    background:$color-yellow;
  }
  a {
    background:$color-grey-light;
    border:1px solid #666;
    color:$color-black;
    margin-right:5px;
    padding:0.75em 1em;
    text-decoration: none;
    transition: $default_transition;
    &:hover {
      background:$color-yellow;
      color:#000;
    }
  }
}

/*
.single {
  .entry-content--has-feature-image {
    padding-top:40%;

    @include media-minwidth-sm() {
      padding-top:13em;
    }

    @include media-minwidth-md() {
      padding-top:11em;
    }

  }
} */

