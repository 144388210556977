.black-and-yellow-contrast {

  background:$color-yellow;
  color:$color-black;

  .skip-link.screen-reader-text {
    background:$color-yellow;
    color:$color-yellow;
  }

  .sh__masthead {
    background:$color-yellow;
    border-bottom:1px solid $color-black;
  }

  .sh__masthead__container__sub_menu {
    ul {
      li {
        &.menu-item-has-children {
          ul.sub-menu {
            background: $color-yellow;
            border:1px solid $color-black;
          }
        }
      }
    }
  }

  .sh__content__container .sh__content__container_header {
    background: transparent;
    .header-main-nav {
      > ul {
        .menu-item {
          border-color: $color-yellow;
        }

        .active {
          border-color: $color-black;
        }

        .menu-donate {
          a {
            background:$color-yellow;
            border:1px solid $color-black;
            color:$color-black;
          }
        }

      }

    }
  }

  .yellow-page-header.container {
    background:$color-yellow;
    border:1px solid $color-black;
  }

  .block_bg_ {
    &fuschia,
    &blue,
    &yellow,
    &mint,
    &black,
    &sand {
      background:$color-yellow;
      border:1px solid $color-black;
      color:$color-black;

      a {
        color:$color-black;
      }

    }
  }

  .vacancy-grid {
    .vacancy-grid--item {
      background:$color-yellow;
      border:1px solid $color-black;
      color:$color-black;

      .apply-button {
        background:$color-yellow;
        border:1px solid $color-black;
        color:$color-black;
      }

    }
  }

  .collapsible-block {
    .collapsible-block--container {

      .collapsible-block-toggle button {
        background:$color-yellow;
        border:1px solid $color-black;
        color: $color-black;
        &.collapsible-button-active {
          background:$color-yellow;
          font-weight:bold;
        }
      }

      .collapsible-block--items-items {
        background: $color-yellow;
        border:1px solid $color-black;
        button {
          color:$color-black;
        }
        &.collapsible-open,
        &:hover {
          background: $color-yellow;
          border:1px solid $color-black;
        }
      }
    }
  }

  .quote-block {
    .quote-no-image {
      .quote-content {
        border-style: solid;
        border-width: 1px;
      }
    }

    .quote-content {
      border:0;
    }
  }

  .cta {
    border:1px solid $color-black;
    background:$color-yellow;
    color:$color-black;

    &:hover {
      background:inherit;
      text-decoration: underline;
    }

  }

  #post-categories-filter {
    .post-active-category,
    .post-category {
      background:$color-yellow;
      border:1px solid $color-black;
      color:$color-black;
    }
    .post-active-category {
      font-weight: bold;
    }
  }

  #post-grid-container {
    article {
      span {
        background:$color-white;
        border:1px solid $color-black;
        color:$color-black;
      }
    }
  }

  .post-navigation {
    .current,
    a {
      background:$color-yellow;
      border:1px solid $color-black;
      color:$color-black;
    }
  }

  .gform_wrapper {
    form {
      .gf_page_steps {
        .gf_step {
          background:$color-yellow;;
          border:1px solid $color-black;
          color: $color-black;

          &.gf_step_active {
            font-weight:bold;
            border-left:1px solid $color-black!important;
          }

        }
      }
    }
  }

  input[type=text],
  input[type=password],
  input[type=email],
  input[type=number],
  input[type=tel],
  select,
  textarea, input[type=submit], input[type=button] {
    background:$color-yellow;
    border-color: $color-black;
    color:$color-black;
  }

  input[type=submit], input[type=button] {
    border:1px solid;
    &:hover {
      background:$color-yellow;
      text-decoration: underline;
    }
  }

  .search-results  {
    article {
      a:hover {
        background:$color-white;
        border:1px solid $color-black;
      }
    }
  }

  .share-icons-container {
    color:$color-black!important;
    #share-1 > div {
      color:$color-black!important;
    }
    i {
      color:$color-black!important;
    }
  }

  .sf {
    background:$color-yellow;
    border-top:1px solid $color-black;
    color:$color-black;

    svg {
      path,
      polygon,
      polyline {
        fill:$color-black;
      }
    }

    a {
      color:$color-black;
    }

    .sf__footer__top {
      a {
        &:hover {
          color:$color-black;
        }
      }
    }

    .sf__container__top {
      svg {
        fill: $color-black!important;
      }
    }

    .sf__footer--right {
      background:$color-yellow;
      border:1px solid $color-black;
    }

  }


  #sticky-tab div a {
    background:$color-yellow;
    border:1px solid $color-black;
    color:$color-black;
  }


}
