#content {
  margin:auto;
  padding:0;
}

.container {
  padding: 1em;
  width: 100%;

  /* @include media-minwidth-sm {
    max-width: $breakp-sm-width;
  }

  @include media-minwidth-md {
    max-width: $breakp-md-width;
  }

  @include media-minwidth-lg {
    max-width: $breakp-lg-width;
  } */

  @include media-minwidth-xl {
    max-width: $breakp-xl-width;
  }
}

.w-100 {
  width: $size-full;
}

.h-100 {
  height: $size-full;
}

.m-auto {
  margin: $size-auto;
}

.btn {
  background: $color-grey-light;
  border-radius: 10px;
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  margin: 1em 0 0 0;
  padding: 0.75em 1.75em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: $default_transition;
  &:before {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    left: 13px;
    padding-right: 2px;
    position: absolute;
    top: 12px;
    transition: $default_transition;
  }
  &:hover {
    color: #fff;
    transition: $default_transition;
    &:before {
      color: #fff;
      transition: $default_transition;
    }
  }
}

@include media-minwidth-md {
  .w-md-1-4 {
    flex-basis: $size-1-4;
  }

  .w-md-3-4 {
    flex-basis: $size-3-4;
  }
}