#user-list {
  display: flex;
  flex-wrap: wrap;
  margin:2em 0 0 0;
  .user-info {
    margin-bottom:3em;
    width:100%;
    @media(min-width:$breakp-md-width) {
      display: flex;
      margin-bottom:2em;
      padding-right:2em;
      width:45%;
    }
    h3 {
      margin-bottom:0;
    }
    img {
      margin-bottom:0.5em;
      @media(min-width:$breakp-md-width) {
        margin-right:1em;
      }
    }
    p.no-margin {
      margin:0;
    }
  }
}