/* Top sub menu */

.sh__masthead__container__sub_menu {
  align-items: center;
  display: flex;

    ul {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-left: 1em;
        position: relative;

        &.active {
         > a {
            border-bottom: 1px solid #000;
          }
        }

        &.menu-item-has-children {

          ul.sub-menu {
            background: rgba(237, 237, 237, 1);
            display: flex;
            flex-direction: column;
            left: 0;
            height: auto;
            padding: 0;
            position: absolute;
            top: inherit;
            transform: scaleY(0);
            transform-origin: top left;
            transition: transform 0.2s $easeOutQuint, visibility 0.1s $easeOutQuint;
            visibility: hidden;
            width: 200px;
            z-index:9999;
            li {
              border: 0;
              margin: 0;
              padding: 0;
              width:100%;
              &.d-none {
                display: none;
              }

              &.active {
                a {
                  border:0;
                  font-weight:bold;
                }
              }


                a {
                display: block;
                font-size:0.9em;
                padding:0.5em;

                &:hover {
                  font-weight:bold;
                }

                &.menu-back {
                  display: none;
                }
              }
            }
          }

          &:hover {
            .sub-menu {
              transform: scaleY(1);
              transition: transform 0.2s $easeOutQuint, visibility 0.4s $easeOutSine;
              visibility: visible;
            }
          }

        }

        i {
          font-weight: 500;
        }

      }
    }


}


/* Mobile menu */
.header-main-nav {
  background: $color-black;
  height: 100vh;
  left: 0;
  position: fixed;
  left: -100%;
  top: 0;
  width: 60%;
  z-index: 9999;
  transition: all .2s ease-in-out;

  ul {
    margin: 0;
    padding: 0;

    li {
      background: $color-black;
      border-bottom: 1px solid $color-grey-light;
      list-style: none;
      margin:0;

      &.active,
      &:hover {
        background: #fff;
        > a {
          color: $color-black;
        }
      }

      &.menu-item-has-children {

        > a {
          position: relative;
          width:80%;

          /* &:before {
            content: "\f105";
            color: #fff;
            font-family: "Font Awesome 5 Pro";
            font-size: 22px;
            padding-right: 6px;
            position: absolute;
            right: 15px;
            top: 13px;
            vertical-align: middle;
          } */
        }

        span {
          color: #ffffff;
          cursor: pointer;
          font-size: 22px;
          padding: 17px 15px;
          position: absolute;
          right: 0;
          vertical-align: middle;
        }

        ul.sub-menu {
          background-color: $color-black;
          height: 100%;
          left: 0;
          list-style: none;
          display: block;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          transition: all .2s ease-in-out;
          width: 100%;
          z-index: 7;
          &.in {
            transform: translateX(0);
          }
        }

        &:hover,
        &.active {
          > a {
            &:before {
              color: $color-black;
            }
          }

          span {
            color:$color-black;
          }


        }

      }
    }
    a {
      color: #fff;
      display: block;
      padding: 1em;
    }
  }

}

/* desktop menu */

@include media-minwidth-lg {

  .header-hamburger-search {
    display: none!important;
  }

  .header-main-nav {
    align-items: center;
    background: none;
    border-bottom: 0;
    display: flex;
    height: auto;
    flex-flow: row nowrap;
    justify-content: center;
    left: 0;
    position: relative;
    top: 0;
    width: auto;
    transition: none;

    > ul {
      display: flex;

      .menu-item {
        background: none;
        border:0;
        border-bottom:2px solid $color-yellow;
        @include list-reset;
        margin: 0 0.5rem;
        position: relative;

        &.active,
        &:hover {
          background: none;
          border-bottom:2px solid #000;
        }

        &.d-none {
          display:none;
        }

        a {
          cursor: pointer;
          color: #000;
          font-size:1.2em;
          padding:0.5em 0;
        }

        &.menu-item-has-children {
          > a:before {
            content: "\f107";
            color:#000;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            position: absolute;
            right:5px;
            top:12px;
          }

          span {
           display:none;
          }

          a {
            padding-right:1.25em;
            width:100%;
          }

          .sub-menu {
            background: rgba(237, 237, 237, 0.9);
            left: 0;
            height: auto;
            padding: 0;
            position: absolute;
            top: inherit;
            transform: scaleY(0);
            transform-origin: top left;
            transition: transform 0.2s $easeOutQuint, visibility 0.1s $easeOutQuint;
            visibility: hidden;
            width: 250px;
            li {
              &.d-none {
                display: none;
              }

              &.active {
                > a {
                  font-weight:bold;
                }
              }

              &.menu-donate {

                a {
                  background:none;
                  color:#000;
                }

              }

              border: 0;
              margin: 0;
              padding: 0;
              a {
                font-size:1em;
                padding: $size-3 1.5em;

                &:before {
                  content: "\f105";
                  color: $color-black;
                  font-family: "Font Awesome 5 Pro";
                  font-size: 20px;
                  left: 10px;
                  margin: 0;
                  position: absolute;
                  top: 10px;
                }

                &.menu-back {
                  display: none;
                }

                &:hover {
                  font-weight: bold;
                }

              }
            }
          }

          &:hover {
            .sub-menu {
              transform: scaleY(1);
              transition: transform 0.2s $easeOutQuint, visibility 0.4s $easeOutSine;
              visibility: visible;
            }
          }
        }

        &:focus-within {
          ul.sub-menu {
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
          }
        }

        &.menu-donate {

          &:hover,
          &.active {
            border:0;
            text-decoration: none;
          }

          a {
            background:#000;
            color:#fff;
            font-family: "helvetica", "sans-serif";
            padding:0.5em 2em 0.5em 1.5em!important;
            border-bottom-right-radius: 25px;
            transition: $default_transition;
            &:hover {
              background:$color-black;
              border-bottom-right-radius: 0;
              color:$color-white;
              text-decoration: none;
              transition: $default_transition;
            }
          }
        }


      }
    }
  }

}

@include media-minwidth-xl() {

  .header-main-nav {
    > ul {
      .menu-item {
        margin:0 0.5em;
        a {
          font-size:1.2em;
        }
      }
    }
  }

}