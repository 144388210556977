html {
}

body {
  font-family: $font-family;
  font-size: $text-base;
  color: $color-black;
  font-weight: $font-weight-normal;
}

.d-flex {
  display: flex;
}

.m-auto {
  margin: auto;
}

.text-center {
  text-align: center;
}

.yellow-highlight {
  color:$color-yellow;
}

.lead-paragraph {
  font-family: $arial-rounded;
  font-size:1.2em!important;

  @include media-minwidth-lg() {
    font-size:1.4em!important;
  }

 }

.page-id-1223 {

  #content {
    margin-bottom:5em;
    @include media-minwidth-md() {
      margin-bottom:9em;
    }


  }

  .share-icons-block {
    display: none;
  }
}