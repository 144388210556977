.feature-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height:450px;

  @include media-minwidth-lg() {
    height:650px;
  }
  
  @include target-ieonly() {
    display: flex;
  }

    .feature-grid-1 {
      grid-column: 1 / span 2;
      @include media-minwidth-md() {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
      }

      @include target-ieonly() {
        width:50%;
      }

    }

    .feature-grid-2 {
      @include media-minwidth-md() {
        grid-column: 2 / span 2;
      }

      @include target-ieonly() {
        width:50%;
      }

    }

    .feature-grid-3 {

      @include media-minwidth-md() {
        @include curve-bottom-right();
        grid-column: 2 / span 2;
      }

      @include target-ieonly() {
        width:50%;
      }

      .feature-grid--item-overlay {


        @include media-minwidth-md() {
          @include curve-bottom-right();
        }


      }

    }

  .feature-grid--item {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: $default_transition;

    a {
      height:100%;
      left:0;
      position: absolute;
      top:0;
      width:100%;
      z-index:8888;
    }

    &.feature-grid-1 {
      h2 {
        font-size:1.2em;
        @include media-minwidth-lg() {
          font-size:2.1em;
        }
      }
    }

    &.feature-grid-2,
    &.feature-grid-3 {
      h2 {
        font-size:1.2em;
        @include media-minwidth-lg() {
          font-size:1.7em;
        }
      }
    }

    h2 {
      bottom:0;
      color:#fff;
      padding:0.75em 1em;
      position: absolute;
      width:100%;
      z-index:888;

      @include media-minwidth-md() {
        padding:0.75em 1.25em;
        width:60%;
      }

      span.feature-grid-more {
        font-size:0.8em;
      }

    }

    .feature-grid--item-overlay {
      @include black-bottom-overlay-gradient();
    }



  }

}

.home {
  .feature-grid {
    @include media-minwidth-xl() {
      width:86.8%;
    }

  }
}