.yellow-page-header.container {
  background:$color-yellow;
  justify-content: space-between;
  margin:auto;
  padding: 2em 1.5em 2em 1.5em;
  position: relative;

  &.entry-content--has-feature-image {
    margin-bottom:35%;
    @include media-minwidth-md() {
      position: relative;
      margin-bottom:6em;
    }

    @include media-minwidth-lg() {
      margin-bottom:9em;
    }
  }

  @include media-minwidth-md() {
    display: flex;
    margin-bottom: 0;
    padding: 2em 1.5em 2em 1.5em;
  }

  @include media-minwidth-lg() {
    margin-bottom: 3em;
  }

  h1 {
    margin:0;
    @include media-minwidth-lg() {
      font-size: 3em;
    }
    @include media-minwidth-xl() {
      font-size: 4em;
    }
  }

  .page-header--feature-image {
    left:0;
    margin:auto;
    max-width:500px;
    padding:0 1.5em;
    position: absolute;
    right:0;
    text-align: center;

    @include media-minwidth-md() {
        left:auto;
        right:0;
        top: 20px;
        max-width:100%;
        width:50%;
        z-index: 88;
      }

    @include media-minwidth-lg() {
      width:45%;
    }

    img {
      margin:1em 0;
      @include curve-bottom-right();
      @include media-minwidth-lg() {
        margin:0;
      }
    }
  }

}