@mixin list-reset() {
  list-style: none;
  padding: 0;
}

@mixin media-minwidth-sm {
  @media (min-width: $breakp-sm-width) {
    @content;
  }
}

@mixin media-minwidth-md {
  @media (min-width: $breakp-md-width) {
    @content;
  }
}

@mixin media-minwidth-lg {
  @media (min-width: $breakp-lg-width) {
    @content;
  }
}

@mixin media-minwidth-xl {
  @media (min-width: $breakp-xl-width) {
    @content;
  }
}

@mixin target-ieonly {
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin black-bottom-overlay-gradient() {
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 34%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.8) 70%, rgba(0,0,0,0.8) 98%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 34%,rgba(0,0,0,0) 40%,rgba(0,0,0,0.8) 70%,rgba(0,0,0,0.8) 98%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 34%,rgba(0,0,0,0) 40%,rgba(0,0,0,0.8) 70%,rgba(0,0,0,0.8) 98%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  bottom:0;
  height:100%;
  left:0;
  position: absolute;
  width:100%;
}

@mixin curve-bottom-right() {
  border-bottom-right-radius: 135px;
}

@mixin curve-top-right() {
  border-top-right-radius: 135px;
}

@mixin hero-caption() {

  @include curve-bottom-right();
    padding:1.5em 1em;
    width:100%;

  @include media-minwidth-md() {
    padding:2em;
  }

  @include media-minwidth-xl() {
    padding:3em 4em 3em 2.5em;
  }

  .caption-small {
    margin:0;
    font-size:1em;

    @include media-minwidth-xl() {
      font-size:1.3em;
    }

  }

  .caption-large {
    font-size:1.2em;
    font-family: $arial-rounded;
    margin:0.5em 0;

    @include media-minwidth-md() {
      font-size:1.5em;
    }

    @include media-minwidth-xl() {
      font-size:2.5em;
    }

  }
}