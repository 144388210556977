.ss {
  @include target-ieonly {
    /* IE10+ CSS styles go here */
    width: 20%;
    margin-right: 2%;
    float: left;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 0.5em;
      a {
        color: #0A0A0A;
        display: block;
        text-decoration: none;
      }
      a:before {
        content: "\f105";
        color: $color-black;
        font-family: "Font Awesome 5 Pro";
        font-size: 22px;
        padding-right: 6px;
        vertical-align: middle;
      }
      .children {
        margin: 1em;
      }
    }
  }

}

.ss .product-categories {
  margin-bottom: $size-6;
  @include list-reset;
  .children {
    display: none;
  }
}
