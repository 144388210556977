
@font-face {
  font-family: arial-rounded-bold;
  src: url('_src/fonts/arial-rounded-bold.TTF');
}

h1, h2, h3, h4, h5 {
  font-family: $arial-rounded;
}

h1 {
  font-size: 2em;
  @include media-minwidth-lg() {
    font-size: 3em;
  }
}

h2 {
  font-size: 1.5em;

  @include media-minwidth-md() {
    font-size: 1.7em;
  }

  @include media-minwidth-lg() {
    font-size: 2.5em;
  }

}

h3 {
  font-size: 1.4em;
}