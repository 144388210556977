.skip-link.screen-reader-text {
  background:$color-black;
  color:$color-white;
  overflow: hidden;
  padding:0.25em;
  position: absolute;
  text-decoration: none;
  top:0;
  z-index:100;

  &:focus {
    z-index:9999;
  }

}

.bc.container {
  display: none;
  @media (min-width: $breakp-md-width) {
    display: block;
    font-size: 0.825em;
    margin: 0;
    padding: 0;
    p {
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }
}

.logo {
  font-weight:bold;
  width:15em;

  svg {
    overflow: visible;
    width:100%;
  }
}

.sh__masthead {
  background-color: $color-yellow;
  font-size:1.1em;
  position: fixed;
  width:100%;
  z-index:9999;

  @include media-minwidth-lg() {
    background-color: $color-white;
    position: relative;
  }

  a {
    color: $color-black;
    text-decoration: none;
  }
}

.sh__masthead__container.container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 0.9em;
  margin: 0 auto;
  padding: 0.25em 1em;

  @include media-minwidth-lg() {
    padding: 0.5em;
  }

  .sh__masthead__container_logo {
    @include media-minwidth-lg() {
      display: none;
    }
  }

  .sh__masthead__container__contact,
  .sh__masthead__container__sub_menu {
    display: none;

    @include media-minwidth-lg() {
      display: flex;
    }

  }

  .sh__masthead__container_accessible {
    display: none;

    @include media-minwidth-sm() {
      align-items: baseline;
      display: flex;
    }

    .accessible-colour {
      margin-right:2em;
    }

    p {
      margin-bottom:0;
      text-align: center;
    }

    span {
      display: none;
      @include media-minwidth-xl() {
          display: block;
      }

    }

  }

  .sh__masthead__container__contact {
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 1.5em;

      i {
        margin-right: 0.25em;
      }
    }
  }

  .sh__masthead__container__contact {
    
    a {
      display: flex;
    }
    
  @include media-minwidth-lg() {
    span {
      display: none;
    }
  }

  @include media-minwidth-xl() {
    span {
      display: flex;
    }
  }
}

}

.sh__content {
    padding-top:3.8em;
    position: relative;
    z-index:auto;

    @include media-minwidth-lg() {
      padding-top:0;
      /* &:before {
        background: $color-yellow;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 30%;
        z-index: 888;
      } */

    }



 }

.sh__masthead__menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.sh__content__container {
  margin: 0 auto;
  padding:0!important;

  @include target-ieonly {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    padding: $size-4 0;
    justify-content: space-between;
  }

  .sh__content__container_header {
    background: $color-yellow;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding:0!important;
    position: relative;


    @include media-minwidth-lg() {
      padding:1.5em!important;
    }

    a {
      text-decoration: none;
    }

  }

}

.sh__content__logo {
  display: none;
  @include media-minwidth-lg() {
    display: block;
    position: relative;
    z-index:9999;
  }
}

.nav-open .header-main-nav {
  box-shadow: 0.625rem 0 1.375rem -0.5rem rgba(0, 0, 0, .35);
  left: 0;
  transition: all .2s ease-in-out;
}

@media(max-width: $breakp-lg-width) {
  body.nav-open #content:after {
    background-color: rgba(0, 0, 0, .6);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;
  }
}

.mobLogin {
  @include media-minwidth-lg() {
    display: none!important;
  }
}

.header-hamburger-search {
  align-items: center;
  display: flex;
  .mobile-search,
  .mobile-profile {
    font-size:1.4em;
  }

  .mobile-profile {
    margin:0 0.5em 0 1em;
  }
}

/* @include media-minwidth-lg {
  .header-main-nav {
    ul {
      .menu-item {
        a {
          font-size:1em;
        }
      }
    }
  }
} */

.sh__content__container_menu {
  align-items: center;
  display: flex;
}

/* .menu-donate {
  display: none;
  border-bottom:2px solid $color-yellow;

  @include media-minwidth-lg() {
    display: block;
  }

  &:hover {
    border-bottom:2px solid $color-yellow;
  }

  input[type=submit] {
    background:#000;
    color:#fff;
    font-family: "helvetica", "sans-serif";
    padding:1em 2em 1em 1.5em!important;
    border-bottom-right-radius: 25px;
    transition: $default_transition;
    &:hover {
      background:$color-black;
      border-bottom-right-radius: 0;
      color:$color-white;
      transition: $default_transition;
    }
  }
} */