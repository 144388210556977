
.gform_wrapper {

  margin:2em 0;

  h3.gform_title {
    font-size:2em!important;
  }

  .gform_body {
    margin:2em 0;

    h2 {
      font-size:1.7em;
    }

    .gsection_description {
      font-size:inherit;
      margin:1em 0;
    }

  }

  form {

    .gf_page_steps {
      border:0;
      margin-top:2em;

      .gf_step {
        background:$color-black;
        color:$color-white;
        height:auto;
        margin:0;
        padding:0.5em 2em;
        opacity: 1;
        width:auto;
        span {
          font-size:1.4em;
          margin:0;
        }
      }

      .gf_step_first {
        border-left:0!important;
      }


      .gf_step_active,
      .gf_step_previous,
      .gf_step_completed {
        border-left:1px solid #000;
      }

      .gf_step_active,
      .gf_step_previous,
      .gf_step_completed {
        background:$color-yellow;
        color:$color-black;
      }
    }


    li.gfield {
      margin-bottom:2em;

      &.gfield_error {
        background: none!important;
        border:0!important;
        color:#ff0000!important;
        padding:0!important;

        label {
          color:#000;
        }

        .validation_message {
          color:#ff0000!important;
        }
      }
    }

    label {
      font-weight:normal!important;
    }

    /* ul.gfield_checkbox {
      align-items: center;
      display: flex!important;
      li {
        margin-right:1em;
        label {
          align-items: center;
          display: flex !important;
        }
      }
    } */

    .ginput_container_radio {
      margin-top:0!important;
    }

    .validation_error {
      border:0;
      color:red;
      margin-bottom:0;
      text-align: left;
    }

    .gform_next_button {
      float:right;
    }

  }
}


