.page-template-block-page {
  #content {
    padding:0;
    max-width:100%;

    &.widecolumn {
      margin:2em auto;
      max-width:$breakp-lg-width;
    }


  }
}