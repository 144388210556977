.donate-block {
  position: relative;

  .donate-block-bg {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding:4% 0;
  }

  .content-donate--container {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;
    position: relative;
    z-index:888;

    @include media-minwidth-lg() {
      grid-gap: 75px;
      grid-template-columns: 1fr 60%;
    }
    
    @include target-ieonly() {
      display: flex;
      flex-direction: column;

      @include media-minwidth-md() {
        flex-direction: row;
      }

    }

    h2.donate-title {
      font-size:2em;

      @include media-minwidth-lg() {
        font-size:4em;
      }

    }

    .donate-item-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: $arial-rounded;
      margin-bottom:2em;
      max-width:600px;


      @include media-minwidth-md() {
        flex-direction: row;
        max-width:100%;
      }

      a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        justify-content: space-between;
        width:100%;

        @include media-minwidth-md() {
          flex-direction: row;
        }

        &:hover {
          .donate-item--content {
            border-bottom-right-radius: 0;
            transition: $default_transition;
          }
        }

      }

      .donate-item--content {
        @include curve-bottom-right();
        font-size:1.3em;
        padding:1em 2em 1em 1em;
        width:100%;
        transition: $default_transition;

        @include media-minwidth-md() {
          font-size:1.4em;
          max-width:20em;
          width:55%;
        }

        @include media-minwidth-lg() {
          font-size:1.6em;
          width:55%;
        }

      }

      .donate-item--cost {
        font-size:3em;
        margin-bottom:0.25em;
        text-align: center;
        width:100%;

        @include media-minwidth-md() {
          font-size:3em;
          text-align: right;
          width:6em;
        }

        @include media-minwidth-lg() {
          width:40%;
        }

      }

    }

  }

  .donate-overlay {
    background:rgba(225, 225, 225, 0.75);
    height:100%;
    left:0;
    position: absolute;
    top:0;
    width:100%;
    z-index:555;
  }

}

