.hero-block {
  height:500px;
  position: relative;

  @include media-minwidth-lg() {
    height:600px;
  }

  @include media-minwidth-xl() {
    height:900px;
  }

  .hero-block-slide {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:cover;
    height:100%;
    @include curve-bottom-right();

    @include media-minwidth-lg() {
      border-bottom-right-radius: 0;
    }

  }

  .hero-caption {
    @include hero-caption();
    bottom:0;
    position: absolute;

    @include media-minwidth-lg() {
      position: relative;
      width:40%;
    }

  }

}