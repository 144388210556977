.collapsible-block {

  .collapsible-block--container {
    margin:auto;
    max-width:$thin-content;
    padding:0 3em;

    .collapsible-block-toggle {
      text-align: right;
      margin:2em 0 0 0;
      button {
        background:$color-black;
        color:$color-white;
        padding:0.5em 1em;
        transition: $default_transition;

        &.collapsible-button-active {
          background:$color-yellow;
          color:$color-black;
          transition: $default_transition;
        }

      }
    }

    .collapsible-block--content {
      text-align: center;
      h2 {
        margin-bottom:1em;
      }
    }

    .collapsible-block--items {
      margin:1.5em 0 2em 0;
    }

    .collapsible-block--items-items{
      background:$color-black;
      margin:1em 0;
      padding:0.75em 1em;
      transition: $default_transition;

      &:hover,
      &.collapsible-open {
        background:$color-yellow;
        transition: $default_transition;

        button {
          color:$color-black;
          transition: $default_transition;
        }
      }

      button {
        color:$color-white;
        cursor: pointer;
        display: inline-block;
        font-size:1.3em;
        margin:auto;
        text-align: left;
        transition: $default_transition;
        width:100%;
      }

      .collapsible-block--items-items-content {
        color:$color-black;
        display: none;
        padding:1em 0;
      }

    }

  }

  &.block_bg_black {
    .collapsible-block--items-items {
      background:$color-white;

      h3 {
        color:$color-black;
      }

    }
  }

  &.block_bg_yellow {

    .collapsible-block--items-items {

      &:hover,
      &.collapsible-open {
        background: $color-black;

        h3 {
          color: $color-white;
          transition: $default_transition;
        }
      }

      .collapsible-block--items-items-content {
        color:$color-white;
      }


    }


  }


}