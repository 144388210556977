.content-block {
  .content-block--container {
    position: relative;
    z-index:888;
    .content-block--container-columns {
      margin: 2em 0;

      ul {
        margin-bottom:2em;
      }

    }

    .content-block--container--80 {
      margin:auto;

      @include media-minwidth-lg() {
        margin:auto;
        max-width:80%;
      }

    }

    .content-block--container--70 {
       @include media-minwidth-lg() {
        margin:auto;
        max-width:70%;
      }

    }

    .content-block--container--70-30 {
      display: grid;
      
      @include media-minwidth-lg() {
        grid-template-columns: 60% 35%;
        grid-gap: 50px;
      }

      @include target-ieonly() {
        display: flex;
        flex-direction: column;

        @include media-minwidth-lg() {
          flex-direction: row;
        }

        .content-block-col-1 {
          @include media-minwidth-lg() {
            width:70%;
          }
        }

        .content-block-col-2 {
          @include media-minwidth-lg() {
            width:30%;
          }
        }

      }
      
    }

    .content-block--container--50-50 {
      
      display: grid;
      grid-template-columns: 1fr;
      
      @include media-minwidth-lg() {
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
      }

      @include target-ieonly() {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-minwidth-lg() {
          flex-direction: row;
        }

        .content-block-col-1 {
          @include media-minwidth-lg() {
            width:45%;
          }
        }

        .content-block-col-2 {
          @include media-minwidth-lg() {
            width:45%;
          }
        }

      }
      
    }

    .content-block-withbg {
      padding:3em;
    }

    .content-block-withcurve {
      @include curve-bottom-right();
    }

  }
}