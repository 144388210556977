.grid-block-content {
  margin:1em auto;


  @include media-minwidth-lg() {
    max-width: $breakp-lg-width;
  }


}

.grid-block--container {
  display: grid;
  grid-gap:50px;
  grid-template-columns: repeat(1, minmax(200px, 445px));
  justify-content: center;
  margin:2em 0;

  @include media-minwidth-sm() {
    grid-template-columns: repeat(2, minmax(200px, 300px));
  }

  @include media-minwidth-lg() {
    grid-template-columns: 30% 30% 30%;
  }

  @include target-ieonly() {
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-start;
  }

  &.grid_image_null {
    .grid-block--container--tile {
      .tile-title {
        h3 {
          color: #0A0A0A;
        }
      }
      span {
        color: #0A0A0A;
        opacity: 1;
        padding:1em 0;
        position: relative;
      }
    }
  }

  &.grid_image_behind {
    .grid-block--container--tile {
      background-size: cover;
      overflow: hidden;
      padding-bottom:65%;

      .tile-title {
        padding:1em;
        position: absolute;
        h3, p {
          color: #fff;
        }
      }
      span {
        color: #fff;
      }
    }
  }

  &.grid_image_above {

    .grid-block--container--tile {

      img {
        display: block;
        margin: auto;
      }

      span {
        color: #0A0A0A;
        opacity: 1;
        padding: 1em 0;
        position: relative;
      }
    }
  }

    .grid-block--container--tile {
      position: relative;

    @include target-ieonly() {
      margin:0.5em;
      height:300px;
      width:80%;

      @include media-minwidth-sm() {
        height:auto;
        width:45%;
      }

      @include media-minwidth-md() {
        height:auto;
        margin:0 2em 2em 0;
        width:29%;
      }

    }

    .tile-title {
      bottom:0;
      left:0;
      padding:1em 0;
      width:100%;
      z-index:8888;
      transition: 0.3s all ease-in-out;
      h3 {
        font-size:1.2em;
        font-weight:normal;
        margin:0;

        @include media-minwidth-xl() {
          font-size:1.5em;
        }

      }
      p {
        margin:0.5em 0;
      }
    }

    span {
      color:#fff;
      opacity: 0;
      position: absolute;
      bottom: 0;
      padding: 1em;
      z-index:8888;
      transition: 0.3s all ease-in-out;
    }

    .tile-overlay {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7db9e8+0,2d3793+100&0+0,1+100 */
      background: -moz-linear-gradient(top, rgba(125,185,232,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(125,185,232,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(125,185,232,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#2d3793',GradientType=0 ); /* IE6-9 */
      bottom:0;
      height:100%;
      left:0;
      position: absolute;
      width:100%;
      z-index:5555;
      transition: 0.3 all ease-in-out;
    }

    &.grid-block--container--tile-link {
      transition: 0.3s all ease-in-out;
      &:hover {
        .tile-title {
          bottom:30px;
          transition: 0.3s all ease-in-out;
        }
        span {
          opacity: 1;
        }

      }
    }

    a {
      height:100%;
      left:0;
      position: absolute;
      top:0;
      width:100%;
      z-index:8888;
    }
  }
}