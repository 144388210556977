.colour-contrast-wrap {
  width:100%;
}

.contrast-button-toggle {
  border: 2px solid #1d1d1b;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  display: block;
  height: 21px;
  margin: 2px auto;
  width: 21px;
}

.contrast-active {
  transform: scale(1.2);
  transition: 0.3s all ease-in-out; }

.contrast-button-toggle {
  background: linear-gradient(-90deg, white, white 49%, white 49%, white 51%, #1d1d1b 51%); }

.yellow-and-black-contrast .contrast-button-toggle {
  background: linear-gradient(-90deg, #1d1d1b, #1d1d1b 49%, white 49%, white 51%, yellow 51%); }

.white-and-black-contrast .contrast-button-toggle {
  background: linear-gradient(-90deg, #1d1d1b, #1d1d1b 49%, white 49%, white 51%, white 51%); }

.black-and-yellow-contrast .contrast-button-toggle {
  background: linear-gradient(-90deg, yellow, yellow 49%, white 49%, white 51%, #1d1d1b 51%); }