.team-grid {
  display: grid;
  grid-template-columns: minmax(200px, 300px);
  grid-gap: 30px;
  justify-content: center;

  @include media-minwidth-sm() {
    grid-template-columns: 1fr 1fr;
  }

  @include media-minwidth-lg() {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include target-ieonly() {
    display: flex;
    flex-wrap: wrap;
  }

  .team-grid--item {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom:75%;
    position: relative;
    transition: $default_transition;

    @include target-ieonly() {
      margin:1em;
      padding-bottom:25%;
      width:20em;

      @include media-minwidth-md() {
        padding-bottom:25%;
        width:30%;
      }

    }

    &:hover {
      @include curve-bottom-right();
      transition: $default_transition;
      .team-grid--item-content {
        opacity: 1;
        transition: $default_transition;
      }
    }

    .team-grid--item-content {
      @include black-bottom-overlay-gradient();
      bottom:0;
      color:#fff;
      font-family: $arial-rounded;
      font-size:1em;
      height:auto;
      opacity: 0;
      padding:9em 1em 1em 1em;
      position: absolute;
      width:100%;
      transition: $default_transition;
      @include curve-bottom-right();

      @include media-minwidth-xl() {
        font-size:1.4em;
      }
    }

    h3 {
      margin:0;
      padding:0.75em;
    }

    a {
      bottom:0;
      height:100%;
      position: absolute;
      left:0;
      width:100%;
    }
  }
}

.team-member-category {
  margin:1em 0 0 0;
}

.team-grid-archive {
  margin:1.5em 0 4em 0;
}

.view-all-team {
  text-align: center;
}