.vacancy-content {
  margin-bottom:2.5em;

  @include media-minwidth-md() {
    width:70%;
  }

  @include media-minwidth-lg() {
    width:50%;
  }

}

.vacancy-grid {
  display: grid;
  grid-template-columns: minmax(200px, 300px);
  grid-gap: 25px;
  justify-content: center;

  @include media-minwidth-sm() {
    grid-template-columns: 1fr 1fr;
  }

  @include media-minwidth-md() {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-minwidth-lg() {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  @include target-ieonly() {
    display: flex;
    flex-wrap: wrap;
  }
  
  .vacancy-grid--item {
    background:$color-yellow;
    height:200px;
    position: relative;
    @include curve-bottom-right();
    transition: $default_transition;

    @include target-ieonly() {
      margin:1em;
      width:30%;

      @include media-minwidth-sm {
        margin:1em;
        width:45%;
      }

      @include media-minwidth-md {
        margin:1em;
        width:30%;
      }

    }

    &:hover {
      @include curve-bottom-right();
      border-bottom-right-radius:0;
      transition: $default_transition;

    }
    
    h3 {
      margin:0;
      padding:0.75em;
    }
    
    .vacancy-grid--item-location-cats {
      display: flex;
      padding:0 1em;
      div {
        margin-right: 0.5em;
      }
    }

    .apply-button {
      background: $color-black;
      bottom:0;
      @include curve-top-right();
      color:$color-yellow;
      font-size:0.825em;
      padding:1em 1.5em;
      position: absolute;
      left:0;
      transition: $default_transition;
      width:9em;
    }

    a {
      bottom:0;
      height:100%;
      position: absolute;
      left:0;
      width:100%;
    }

  }

}