input[type=text], input[type=password], input[type=email], input[type=number], input[type=tel], select, textarea {
  background:$color-grey-light;
  color: $color-black;
  border:1px solid #ccc;
  padding:0.5em!important;
}

input[type=submit],
input[type=button],
#reset-pass-submit {
  background:$color-black;
  border:0;
  color:#fff;
  cursor: pointer;
  font-family: $arial-rounded;
  padding:0.5em 1.5em!important;
  transition: $default_transition;

  &:hover {
    background:$color-yellow;
    color:$color-black;
    transition: $default_transition;
  }
}

input[type=checkbox] + label {
  align-items: center;
  display: flex;
  margin: 0.2em;
  padding: 0.2em;
}

input[type=checkbox] {
  display: none!important;
}

input[type=checkbox] + label:before {
  content: "\f00c";
  border: 0.1em solid;
  border-radius: 0.2em;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  color:#e0e0e0;
  padding: 0.25em;
  margin-right: 0.5em;
  vertical-align: bottom;
  transition: .2s;
  background:#e0e0e0;
}

input[type=checkbox] + label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked + label:before {
  background:#000;
  color:#fff;
}

input[type=checkbox]:disabled + label:before {
  transform: scale(1);
}

input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
}



.select2-container--default .select2-selection--single {
  border-radius: 0;
  background: $color-grey-light;
  color: $color-black;
  border: $color-grey;
  padding: $size-3 $size-4;
  min-height: 2.6rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
  color: $color-black;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 7px;
}