/* Block settings */
.content-block.padding_remove_bottom {
  padding-bottom:0;
}
.content-block.padding_remove_top {
  padding-top:0;
}

.content-block.padding_remove_top_and_bottom {
  padding-bottom:0;
  padding-top:0;
}

.content-container {
  margin:auto;
  max-width:$breakp-xl-width;
  padding:0 1em;
}

.content-block {
  padding:5% 0;

  @include media-minwidth-lg() {
    padding:4% 0;
  }

}

.block-title {
  text-align: center;
  @include media-minwidth-lg() {
    padding:0 3em;
  }
}

.block_bg_black {
  background:$color-black;
  color:#fff;

  a {
    color:$color-white;
  }

}

.block_bg_blue {
  background:$color-sky-blue;
}

.block_bg_yellow {
  background:$color-yellow;
}

.block_bg_mint {
  background:$color-mint;
}

.block_bg_fuschia {
  background:$color-fuschia;
  color:#fff;
}

.block_bg_sand {
  background:$color-sand;
}