#post-categories-filter {
  display: flex;
  flex-direction: column;
  margin:2em 0 0 0;

  @include media-minwidth-sm() {
   flex-direction: row;
    margin:0;
  }

  @include media-minwidth-md() {
    margin:0 1em 0 0;
  }
  
  a {
    background:$color-yellow;
    color:$color-black;
    margin:0.5em 1em 0 0;
    padding:0.5em 2em 0.5em 1em;
    @include curve-bottom-right();
    text-decoration: none;
    transition: $default_transition;

    @include media-minwidth-md() {
      margin:0 1em 0 0;
    }

    @include media-minwidth-md() {
      margin:-15px 1em 0 0;
    }


    &.post-active-category,
    &:hover {
      background: $color-black;
      color:$color-white;
      transition: $default_transition;
  }

  }
}