.share-icons.container {
  align-items: center;
  display:flex;
  margin:1.5em auto;
  padding:0 1em;

  div {
    margin-right:1em;

    &.share-icons--share {
      align-items: center;
      color: green;
      display: flex;
      i {
        color: green;
        margin-left:0.25em;
      }

      &:hover {
        i {
          margin-top:0!important;
        }
      }

    }

    &.share-icons--facebook i {
      color: #3C5A99;
    }

    &.share-icons--twitter i {
      color: #1DA1F3;
    }

    &.share-icons--linkedin i {
      color: #0A66C2;
    }

    i {
      font-size:1.2em;
      transition: 0.1s all ease-in-out;
    }

    &:hover {
      i {
        margin-top:-5px;
        transition: 0.1s all ease-in-out;
      }
    }

  }

}