#loginform,
#lostpasswordform,
#resetpasswordform {
  background:#e0e0e0;
  margin-bottom:1em;
  max-width:400px;
  padding:1em;
  
  @include media-minwidth-lg() {
    max-width:100%;
    width:50%;
  }

  p {
    display: flex;
  }
  
  label {
    float:left;
    width:10em;
  }

  .login-remember {

    label {
      align-items: center;
      display: flex;
    }

    input[type=checkbox] {
      background:#fff;
      display: block!important;
      height:16px;
      margin-right:5px;
      width:20px;
    }
  }
  


}