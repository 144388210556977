$theme-folder: "/wp-content/themes/blindaid" !default;

/* Colours */
$color-transparent: 'transparent';
$color-grey-dark: #3C3C3B;
$color-grey: #B2B2B1;
$color-grey-light: #f7f7f7;
$color-white: #ffffff;
$color-black : #000000;

$color-yellow : #FFED38;
$color-sky-blue : #51DFFF;
$color-fuschia : #FF1FF2;
$color-mint : #69E5C0;
$color-sand : #f5e3e0;
$color-almost-black : #000026;

$color-danger: #de751f;
$color-warning: #f2d024;
$color-success: #1f9d55;
$color-info: #2779bd;

$thin-content: 1000px;

$arial-rounded : 'arial-rounded-bold', sans-serif;

$br-10: 10px;

/* Breakpoints */
$breakp-sm-width: 576px;
$breakp-md-width: 768px;
$breakp-lg-width: 992px;
$breakp-xl-width: 1400px;
$breakp-sm: '(min-width : $breakp-sm-width)';
$breakp-md: '(min-width : $breakp-md-width)';
$breakp-lg: '(min-width : $breakp-lg-width)';
$breakp-xl: '(min-width : $breakp-xl-width)';
/* Fonts */
$font-family: 'helvetica', 'sans-serif';
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-none: 1;
$line-height-tight: 1.25;
$line-height-normal: 1.5;
$line-height-loose: 2;

$letterspacing-tight: -0.05em;
$letterspacing-normal: 0;
$letterspacing-wide: 0.05em;

$text-xs: .75rem; // 12px
$text-sm: .875rem; // 14px
$text-base: 1.1rem; // 16px
$text-lg: 1.125rem; // 18px
$text-xl: 1.25rem; // 20px
$text-2xl: 1.5rem; // 24px
$text-3xl: 1.875rem; // 30px
$text-4xl: 2.25rem; // 36px
$text-5xl: 3rem; // 48px

/* Measures */
$size-auto: auto;
$size-px: 1px;
$size-1: 0.25rem;
$size-2: 0.5rem;
$size-3: 0.75rem;
$size-4: 1rem;
$size-5: 1.25rem;
$size-6: 1.5rem;
$size-7: 1.75rem;
$size-8: 2rem;
$size-10: 2.5rem;
$size-12: 3rem;
$size-16: 4rem;
$size-24: 6rem;
$size-32: 8rem;
$size-48: 12rem;
$size-64: 16rem;
$size-1-2: 50%;
$size-1-3: 33.33333%;
$size-2-3: 66.66667%;
$size-1-4: 25%;
$size-3-4: 75%;
$size-1-5: 20%;
$size-2-5: 40%;
$size-3-5: 60%;
$size-4-5: 80%;
$size-1-6: 16.66667%;
$size-5-6: 83.33333%;
$size-full: 100%;
$size-screen-width: 100vw;
$size-screen-height: 100vh;

/* Transitions */
$default_transition: 0.3s all ease-in-out;