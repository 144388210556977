.cta {
  background: $color-black;
  color:$color-white;
  display: inline-block;
  margin:2em 0;
  padding:0.5em 2em;
  text-decoration: none;
  transition: $default_transition;

  &:hover {
    background:$color-yellow;
    color:$color-black;
    transition: $default_transition;
  }

}