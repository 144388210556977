.sf {
  background:#000;
  color:#fff;
  clear:both;
  margin-top:2em;

  svg {
    path,
    polygon,
    polyline {
      fill:#fff;
    }
  }

  .footer-logo {
    max-width:235px;

    @include media-minwidth-xl() {
      max-width:300px;
    }

    svg {
      width:100%;
    }
  }


  @include target-ieonly {
      float: left;
      width: 100%;
  }

  a {
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }
  h3 {
    margin-bottom: $size-3;
  }
}

.sf__container {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: $size-2;
      }
    }

  .sf__container__top {
    margin:auto;
    padding-top:0;
    @include media-minwidth-lg() {
      display: grid;
      grid-column-gap: 100px;
      grid-template-columns: 1fr 1fr;
      
      @include target-ieonly() {
        display: flex;
        justify-content: space-between;
      }

        svg {
          fill: #fff!important;
          overflow: visible;
         /* width:17em; */
        }

    }
  }

}

.sf__footer__column {

  @include media-minwidth-md() {
    display: flex;
    justify-content: space-between;
  }

  .sub-menu {
    display: none;
  }
}

.sf__footer--left {
  flex-wrap:wrap;
  padding:3em 0;
  width: 100%;

  @include target-ieonly() {
    @include media-minwidth-lg() {
      width:45%;
    }
  }

  .sf__footer__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width:100%;

    @include media-minwidth-sm() {
      flex-direction: row;
    }

    a {
      display: inline-block;
      font-size:2.5em;
      margin:0 0.25em;

      &:hover {
        color:$color-yellow;
        text-decoration: none;
        transform: scale(1.1);
      }

    }

  }

  .sf__footer--left-widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width:100%;
  }


  #footer-logo {
    font-size:3em;
    width:100%;
  }

  .ss__content--footer {
    font-size:1em;
    padding:1em 2em 1em 0;

    @include media-minwidth-md() {
      width:45%;
    }

    @include media-minwidth-xl() {
      font-size:1em;
    }

  }

  .sf__footer--left__social {
    float: left;
    margin: 1.5em 0;
    width: 100%;
    div {
      float: left;
      margin-right: 1em;
      width: 2em;
    }
    @media (min-width: $breakp-lg-width) {
      margin: 0;
    }
  }
}

.sf__footer--right {
  background:$color-yellow;
  color:$color-black;
  margin-top:-2em;
  padding:2em;
  @include curve-bottom-right();

  @include media-minwidth-xl() {
    padding:3em 4em;
  }

  @include target-ieonly() {
    @include media-minwidth-lg() {
      width:45%;
    }
  }

  .ss__content--footer {
    margin-bottom:2em;
    @include media-minwidth-md() {
      margin:0;
      width:45%;
    }

  }

  h3 {
    font-size:1.5em;
    margin:0 0 1em 0;
  }

  ul {
    li {
      margin-top:1em;
      a {
        color:$color-black;
        font-size:0.825em;
        @include media-minwidth-xl() {
          font-size:1em;
        }

      }
    }
  }

}

.sf__bottom {
  display: inline-flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  width: $size-full;
  @media (min-width: $breakp-md-width) {
    align-items: center;
    flex-direction: row;
    font-size: 0.825em;
    justify-content: space-between;
  }

  .footer-legal-nav {
    ul {
      display: flex;
      flex-direction: column;
      margin:1em 0;
      padding:0;

      @media (min-width: $breakp-md-width) {
        flex-direction: row;
        margin:0;
      }

      li {
        list-style: none;
        margin-right:1em;
      }

    }
  }

}

.sf__bottom--container.container {
  display: block;
  padding-top:0;
  p {
    margin: 0 0 0.5em 0;
  }
}

#sticky-tab {
  display: none;

  @include media-minwidth-lg() {
    display: flex;
    position: fixed;
    right:-100px;
    top:75%;
    transform: rotate(-90deg);
    z-index:8888;
    transition: $default_transition;
  }

  &.top-show {
    right:-12px;
    a {
      cursor: pointer;
      opacity: 1;
      transition: $default_transition;
    }
  }

  div {
    margin-right:1em;

    a {
      background: $color-mint;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      color: $color-black;
      display: block;
      font-size:1.2em;
      padding:0.5em 1em;
      text-decoration: none;
    }
  }
}
