.search-form {

  &.small-search-from {
    border: 1px solid $color-black;
    input[type="search"] {
      border: 0;
      width: 90%;
    }
  }
  display: flex;
  float: left;
  margin: 1em 0;
  width: $size-full;
  input {
    padding: 0.75em;
    &[type="search"] {
      border: 1px solid $color-black;
    }
    &[type="submit"] {
      background: $color-black;
      color: #fff;
      cursor: pointer;
    }
  }
  button {
    color: $color-black;
    width: 10%;
    transition: 0.3s all ease-in-out;
    &:hover {
      background: $color-black;
      color: #fff;
      transition: 0.3s all ease-in-out;
    }
  }
}

.search-results {
  article {
    border-bottom: 1px solid $color-grey-light;
    clear: both;
    h2 {
      font-size: 1.5em;
      margin: 0;
    }
    a {
      color: inherit;
      display: block;
      padding: 0.5em;
      text-decoration: none;
      &:hover {
        background: $color-yellow;
        transition: 0.3s all linear;
      }
    }
    &:last-child {
      border-bottom: 0;
    }

  }
}